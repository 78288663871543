.swatch-grid {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    gap: 20px;
    margin-bottom: 35px;

    @include breakpoint(tablet) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
        column-gap: 10px;
        row-gap: 15px;
    }

    @include breakpoint(mobile) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.swatch-grid--s-3-col {
    margin-bottom: 20px;

    @include breakpoint(mobile) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.swatch-grid--form {
    display: flex;
    justify-content: center;

    & > * {
        flex-basis: 110px;

        @include breakpoint(tablet) {
            flex-basis: 50px;
        }
    }
}

.swatch-grid--chooser {
    gap: 10px;
    margin-bottom: 15px;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    
    @include breakpoint(tablet) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
        gap: 5px
    }

    @include breakpoint(mobile) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
}

.swatch-grid--nab-a-swatch {
    gap: 10px;
    margin-bottom: 15px;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    
    @include breakpoint(tablet) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
        gap: 5px
    }

    @include breakpoint(mobile) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
}

.swatch-grid--listing-item {
    margin-bottom: 0;
    gap: 10px;

    @include breakpoint(tablet) {
        grid-template-columns: repeat(7, minmax(0, 1fr));
        gap: 5px
    }

    @include breakpoint(mobile) {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }
}

.swatch-grid--product-config {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 15px;

    & > * {
        flex-basis: 40px;
    }
}

.swatch-grid--block {
    display: flex;
    justify-content: center;

    @include breakpoint(phablet) {
        justify-content: flex-start;
        gap: 0;
    }

    & > * {
        flex-basis: 110px;

        @include breakpoint(phablet) {
            flex-basis: auto;
        }
    }
}

.swatch-grid--pos {
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
}

    .swatch-grid__select-label {
        margin-bottom: 10px;
        display: block;
    }

    .swatch-grid__select {
        max-width: 320px;
        margin-left: 0;
        margin-bottom: 15px; 

        @include breakpoint(tablet) {
            max-width: 100%;
        }
    }

    .swatch-grid__subtitle {
        @include large-subtitle;

        grid-column: -1 / 1;
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 20px;

        @include breakpoint(tablet) {
            flex-wrap: wrap;
        }

        span {
            @include font(14px, 16px);
            @include regularText;

            .overlay-content__swatch-grid & {
                color: $c-pos-grey;
            }
        }
    }

    .swatch-grid__subtitle--small {
        @include subtitle;
    }

    .swatch-grid__short-description {
        margin-bottom: 10px;
        max-width: 75%;

        @include breakpoint(tablet) {
            max-width: 100%;
        }
    }

    .swatch-grid__swatch {
        text-align: left;
        cursor: pointer;

        input:checked + .swatch-grid__img {
            @include swatch-active;
        }

        .swatch-grid--form,
        .swatch-grid--block & {
            cursor: arrow;
            pointer-events: none;
        }

        .swatch-grid--block & {
            @include breakpoint(phablet) {
                pointer-events: auto;
            }
        }
    }

    .swatch-grid__empty-swatch {
        @include aspect(1);

        border: 1px solid $c-grey-border;
    }

    .swatch-grid__swatch--filtered {
        display: none;
    }

        .swatch-grid__img {
            @include swatch-hover;

            margin-bottom: 13px;

            @include breakpoint(tablet) {
                margin-bottom: 8px;
            }

            img {
                width: 100%;
                height: auto;
            }

            .swatch-grid--chooser &,
            .swatch-grid--product-config &,
            .swatch-grid--listing-item &,
            .swatch-grid--nab-a-swatch & {
                margin: 0;
            }

            figcaption {
                display: none
            }
        }
        
        .swatch-grid__img--has-tick {
            figcaption {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 0;
                bottom: 0;
                width: 30px;
                height: 30px;
                background-color: $c-white;
            }
        }

        .swatch-grid__title {
            @include font(14px, 20px);
            @include mediumText;

            @include breakpoint(tablet) {
                @include font(12px, 18px);
            }

            @include breakpoint(phablet) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .swatch-grid--form & { 
                @include breakpoint(tablet) {
                    display: none;
                }
            }

            .swatch-grid--chooser &,
            .swatch-grid--product-config &,
            .swatch-grid--listing-item &,
            .swatch-grid--nab-a-swatch & {
                display: none;
            }
        }

        .swatch-grid__category-name {
            @include font(12px, 18px);

            margin-top: 3px;

            .swatch-grid--form & { 
                @include breakpoint(tablet) {
                    display: none;
                }
            }

            .swatch-grid--chooser &,
            .swatch-grid--product-config &,
            .swatch-grid--listing-item &,
            .swatch-grid--nab-a-swatch & {
                display: none;
            }
        }
