@use 'sass:math';

.focus-carousel {
    @media screen and (min-width: 2560px) {
        margin-left: calc(((2090px - $site-max-width) / -2) - $site-gutter);
        margin-right: calc(((2090px - $site-max-width) / -2) - $site-gutter);
    }

    overflow: hidden;
    margin-left: calc(((100vw - $site-max-width) / -2) - $site-gutter);
    margin-right: calc(((100vw - $site-max-width) / -2) - $site-gutter);

    @include breakpoint(large) {
        margin-right: calc($site-gutter * -1);
        margin-left: calc($site-gutter * -1);
    }

    @include breakpoint(tablet) {
        margin-right: calc($site-mobile-gutter * -1);
        margin-left: calc($site-mobile-gutter * -1);
    }

        .focus-carousel__swiper {
            overflow: visible;
        }

            .focus-carousel__swiper-wrapper {
                align-items: center;
                left: 50%;
            }
            .swiper-initialized .focus-carousel__swiper-wrapper {
                left: initial;
            }

                .focus-carousel__item {
                    position: relative;
                    transition: border 150ms ease-in-out;
                    will-change: border;
                    transition-delay: 100ms;
                    box-sizing: border-box;
                    border: 0px solid transparent;
                    overflow: hidden;
                    transform: translateX(-50%);
                    width: 51.6%;

                    @include breakpoint(tablet) {
                        width: 71.8%;
                    }

                    &:first-child {
                        border-width: 0;
                    }
                    &:not(.swiper-slide-active) {
                        border: 60px solid transparent;

                        @include breakpoint(tablet) {
                            border-width: 20px;
                        }
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 200px;
                        opacity: 0.5;
                        background-image: linear-gradient(to top, $c-dark, rgba($c-dark, 0));
                        transform: translate3d(0, 0, 0);

                        @include breakpoint(tablet) {
                            height: 150px;
                        }
                    }
                }
                .swiper-initialized .focus-carousel__item {
                    transform: initial;

                    &:first-child {
                        border-width: 0;
                    }
                }
                    .focus-carousel__video {
                        @include aspect(math.div(9, 5));

                        transition: transform 0.3s ease-in-out;
                        -webkit-backface-visibility: hidden;

                        @include breakpoint(tablet) {
                            @include aspect(1);
                        }

                        &:hover {
                            transform: scale(1.075);
                        }

                        video {
                            pointer-events: none;
                            position: absolute;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            min-width: 100%;
                            min-height: 100%;
                            margin: auto;
                            object-fit: cover;
                        }
                    }

                    .focus-carousel__image {
                        @include aspect(math.div(9, 5));

                        transition: transform 0.3s ease-in-out;

                        @include breakpoint(tablet) {
                            @include aspect(1);
                        }

                        &:hover {
                            transform: scale(1.075);
                        }

                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .focus-carousel__action {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        transform: translateZ(0);
                        -webkit-transform: translate3d(-50%, 0, 0);
                        bottom: 40px;
                        z-index: 1;

                        @include breakpoint(tablet) {
                            bottom: 24px;
                            left: $site-mobile-gutter;
                            right: $site-mobile-gutter;
                            transform: translateX(0);
                            -webkit-transform: translate3d(0, 0, 0);
                        }
                    }

                        .focus-carousel__item-title {
                            @include small-title;

                            text-align: center;
                            color: $c-white;
                            margin: 0;

                            @include breakpoint(tablet) {
                                width: 100%;
                            }
                        }

        .focus-carousel__nav-wrap {
            display: flex;
            justify-content: center;
            margin-top: -10px;

            @include breakpoint(tablet) {
                display: none;
            }
        }

            .focus-carousel__nav {
                padding: 10px;
                display: grid;
                place-items: center;
                cursor: pointer;

                svg {
                    fill: $c-dark;
                    transition: fill 150ms ease-in-out;
                }

                &:disabled {
                    opacity: 0.5;
                    cursor: default;
                }

                &:hover:not(:disabled) {
                    svg {
                        fill: var(--site-colour-primary);
                    }
                }
            }
}
