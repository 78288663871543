@use 'sass:math';

.showroom-card {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 25px 0;
    border-top: 1px solid $c-grey-border;
    gap: 23px;

    @include breakpoint(phablet) {
        grid-template-columns: 1fr;
        position: relative;
    }

    &:last-child {
        border-bottom: 1px solid $c-grey-border;
    }

    &[hidden] {
        display: none;
    }
}

    .showroom-card__image {
        @include aspect(math.div(4, 3));

        display: flex;
        align-items: flex-start;

        @include breakpoint(phablet) {
            margin-top: 20px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .showroom-card__title {
        @include mediumText;
        @include font(18px, 24px);

        padding-bottom: 3px;
        display: inline-block;
        margin-bottom: 15px;

        @include breakpoint(phablet) {
            position: absolute;
            top: 20px;
        }
    }

    .showroom-card__address {
        margin-bottom: 10px;
    }

    .showroom-card__results {
        margin: 15px 0 10px;
        display: grid;
        gap: 5px;
    }

    .showroom-card__result {
        display: flex;
        gap: 5px;
        vertical-align: middle;

        a {
            @include mediumText;

            text-decoration: underline;
            transition: color 150ms ease-in;

            &:hover {
                color: var(--site-colour-primary);
            }
        }
    }

    .showroom-card__result--match {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .showroom-card__details {
        svg.global-icon-minus {
            display: none;
        }

        summary {
            list-style: none;
        }

        summary::-webkit-details-marker {
            display: none;
        }

        &[open] {
            svg.global-icon-minus {
                display: block;
            }

            svg.global-icon-plus {
                display: none;
            }

            summary {
                color: var(--site-colour-primary);
            }

            svg {
                fill: var(--site-colour-primary);
            }
        }
    }

    .showroom-card__distance {
        @include mediumText;

        color: $c-body-light;
        opacity: 0;
        transition: opacity 3s linear;
    }

    .showroom-card__distance--show {
        opacity: 1;
    }

    .showroom-title-container {
        display: flex;
        justify-content: space-between;
    }

    .showroom-card__opening-title {
        @include mediumText;

        margin-top: 10px;
    }

    .showroom-card__opening-times {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 10px;
    }

        .showroom-card__day {
            @include mediumText;
        }
