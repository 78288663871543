.brochure-form {}

    .brochure-form--shown {
        display: block;
    }

    .brochure-form--hidden {
        display: none;
    }

    .brochure-form .form-error {
        color: #d43354;
        font-weight: 500;
        padding-bottom: 20px;
    }

    .brochure-form .field-error input {
        border-color: #d43354;
    }

    .brochure-form__title {
        @include small-title;

        position: relative;
        text-align: center;
    }

    .brochure-form__form {
        max-width: calc((2 / 3) * 100%);
        margin: 20px auto 0;
        padding: 50px 115px;
        background-color: $c-light-grey;

        @include breakpoint(tablet) {
            max-width: 100%;
            padding: 0;
            background-color: $c-white;
            margin: 20px auto 20px;
        }
    }
