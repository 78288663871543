@use "sass:map";
@import 'site-colours.json';

:root {
    --site-colour-primary: #677D59;
    --site-colour-secondary: #4e7d87;
    --site-text-colour-primary: #fff;
    --site-text-colour-secondary: #fff;
}

@each $label, $data in $colours {
    .site-colour-primary--#{$label} {
        --site-colour-primary: #{map-get($data, 'background-color')};
        --site-text-colour-primary: #{map-get($data, 'color')};
    }

    .site-colour-secondary--#{$label} {
        --site-colour-secondary: #{map-get($data, 'background-color')};
        --site-text-colour-secondary: #{map-get($data, 'color')};
    }

    .block-background--#{$label} {
        background-color: #{map-get($data, 'background-color')};
        color: #{map-get($data, 'color')};
        fill: #{map-get($data, 'color')};
    }

    .block-accent--#{$label} {
        .block__content {
            background-color: #{map-get($data, 'background-color')};
            color: #{map-get($data, 'color')};
        }
    }

    .banner-background--#{$label},
    .product-details__notice.product-notice-background--#{$label},
    .basket-notice.product-notice-background--#{$label} {
        background-color: #{map-get($data, 'background-color')};
        color: #{map-get($data, 'color')};
        fill: currentColor;
    }

    .product-badges__badge-in-stock--#{$label} {
        .product-badges__badge--in-stock {
            background-color: #{map-get($data, 'background-color')};
            color: #{map-get($data, 'color')};
            fill: #{map-get($data, 'color')};
        }
    }

    .product-badges__badge-new--#{$label} {
        .product-badges__badge--new {
            background-color: #{map-get($data, 'background-color')};
            color: #{map-get($data, 'color')};
            fill: #{map-get($data, 'color')};
        }
    }

    .product-badges__badge-sale--#{$label} {
        .product-badges__badge--sale {
            background-color: #{map-get($data, 'background-color')};
            color: #{map-get($data, 'color')};
            fill: #{map-get($data, 'color')};
        }
    }

    .product-badges__badge-easy-access--#{$label} {
        .product-badges__badge--easy-access {
            background-color: #{map-get($data, 'background-color')};
            color: #{map-get($data, 'color')};
            fill: #{map-get($data, 'color')};
        }
    }

    .product-badges__badge-listing-tag--#{$label} {
        .product-badges__badge--listing-tag {
            background-color: #{map-get($data, 'background-color')};
            color: #{map-get($data, 'color')};
            fill: #{map-get($data, 'color')};
        }
    }

    .listing-callout--border-colour--#{$label},
    .block__card-image--border-colour-#{$label},
    .block__card-video--border-colour-#{$label} video {
        border: solid 10px #{map-get($data, 'background-color')};
        background-color: #{map-get($data, 'background-color')};
    }

    .navigation__callout-image--border-colour-#{$label} {
        border: solid 8px #{map-get($data, 'background-color')};
        background-color: #{map-get($data, 'background-color')};
    }
}
