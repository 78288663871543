.block--simple-cta {

    .block__cta {
        text-align: center;

        > * {
            display: inline-block;
        }
    }
}
