.nudge-intro {
    text-align: center;

    form {
        margin: 20px 0;
    }

    svg {
        display: inline-block;
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 15px;
    }
}

.nudge-intro__media--wide {
    margin-bottom: 30px;

    img {
        width: 100%;
        height: auto;
    }

    @include breakpoint(mobile) {
        @include aspect(2/3);

        &:before {
            padding-bottom: 0;
        }
    }  
}

.nudge-intro__title {
    @include small-title;
    @include margin(bottom, 15px);
}
