.block--lookbook {
    text-align: center;

    .block__title,
    .block__text {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;

        @include breakpoint(phablet) {
            max-width: 100%;
        }
    }

    .block__swiper {
        position: relative;

        @include breakpoint(tablet) {
            margin-right: calc($site-mobile-gutter * -1);
        }
    }

        .block__nav-wrap {
            display: flex;
            justify-content: flex-end;
            margin-right: -10px;

            @include breakpoint(tablet) {
                display: none;
            }
        }

        .block__slide {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: 1fr 1fr;
            grid-gap: 10px;

            @include breakpoint(tablet) {
                grid-template-columns: repeat(4, 1fr);
            }

            @include breakpoint(phablet) {
                grid-template-columns: repeat(5, 1fr);
            }
        }

        .block__image {
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .block__image--l-square {
            @include aspect(1);

            grid-column: 1/3;
            grid-row: 1/3;
        }

        .block__image--banner {
            @include aspect(9 / 4);

            grid-column: 3/5;
            grid-row: 1/2;
        }

        .block__image--s-square1 {
            @include aspect(1);

            grid-column: 3/4;
            grid-row: 2/3;
        }

        .block__image--s-square2 {
            @include aspect(1);

            grid-column: 4/5;
            grid-row: 2/3;
        }

        .block__image--narrow {
            @include aspect(4 / 9);

            grid-column: 5/6;
            grid-row: 1/3;

            @include breakpoint(tablet) {
                display: none;
            }

            @include breakpoint(phablet) {
                display: block;
            }
        }
}
