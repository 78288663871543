$narrow-max-width: 900px;

.block {
    margin-top: 80px;
    margin-bottom: 80px;

    @include breakpoint(tablet) {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    &:first-child {
        margin-top: 0;
    }
}

.block[class*='block-background'],
.block--grey {
    margin-left: calc(((100vw - $site-max-width) / -2) - $site-gutter);
    margin-right: calc(((100vw - $site-max-width) / -2) - $site-gutter);
    padding: 80px 0;

    & > * {
        max-width: calc($site-max-width - ($site-gutter * 2));
        margin-left: auto;
        margin-right: auto;
    }

    @include breakpoint(large) {
        margin-left: calc($site-gutter * -1);
        margin-right: calc($site-gutter * -1);
        padding-left: $site-gutter;
        padding-right: $site-gutter;
    }

    @include breakpoint(tablet) {
        margin-left: calc($site-mobile-gutter * -1);
        margin-right: calc($site-mobile-gutter * -1);
        padding: 60px $site-mobile-gutter;
    }
}

.block--grey {
    padding-bottom: 60px;
}

.block--grey {
    background-color: $c-light-grey;
}

    .block__title {
        @include small-title;
    }

    .block__title--large {
        @include medium-title;
    }

    .block__title--small {
        @include large-subtitle;
    }

    .block__nav {
        padding: 10px;
        display: grid;
        place-items: center;
        cursor: pointer;

        svg {
            fill: $c-dark;
            transition: fill 150ms ease-in-out;
        }

        &:disabled {
            opacity: 0.5;
            cursor: default;
        }

        &:hover:not(:disabled) {
            svg {
                fill: var(--site-colour-primary);
            }
        }
    }

@import "mixins"; // @todo bin this

@import 'blocks/block--action';
@import 'blocks/block--callouts';
@import 'blocks/block--carousel';
@import 'blocks/block--categories';
@import 'blocks/block--content';
@import 'blocks/block--crosssells';
@import 'blocks/block--faq';
@import 'blocks/block--features';
@import 'blocks/block--focus-carousel';
@import 'blocks/block--gallery-cta';
@import 'blocks/block--info-panel';
@import 'blocks/block--intro';
@import 'blocks/block--instagram';
@import 'blocks/block--lookbook';
@import 'blocks/block--moodboard';
@import 'blocks/block--narvar';
@import 'blocks/block--nudge';
@import 'blocks/block--products';
@import 'blocks/block--quote';
@import 'blocks/block--range';
@import 'blocks/block--showrooms';
@import 'blocks/block--shop-the-look';
@import 'blocks/block--signup';
@import 'blocks/block--swatches';
@import 'blocks/block--simple-cta';
@import 'blocks/block--svg-with-text';
@import 'blocks/block--text-overlay';
@import 'blocks/block--top-tip';
@import 'blocks/block--video';

@import 'blocks/block-item';
