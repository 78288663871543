.block--range {

    .block__image {
        @include aspect(3 / 2);
        @include margin(bottom, 25px);

        @include breakpoint(tablet) {
            @include margin(bottom, 20px);
        }
    }

    .block__content {
        text-align: center;
    }

        .block__slides {
            position: relative;
            margin: 25px 20px 0;

            @include breakpoint(tablet) {
                margin: 0;
                margin-right: -10px;
                margin-top: 20px;
            }
        }

        .block__title {
            @include mediumText;
            @include font(24px, 36px);
            @include margin(bottom, 7px);

            letter-spacing: 1.5px;

            @include breakpoint(tablet) {
                @include margin(bottom, 15px);
            }
        }

        .block__intro {
            @include font(16px, 24px);

            margin: 0;
        }
}
