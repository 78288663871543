@use 'sass:math';

.block--moodboard {
    max-width: $max-content-width;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    .block__title,
    .block__text {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(phablet) {
            max-width: 100%;
        }
    }

    .block__text {
        margin-bottom: 20px;
    }

    .block__grid {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        gap: 23px;
        margin-top: 40px;

        @include breakpoint(phablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 12px;
        }
    }

        .block__image {
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include breakpoint(phablet) {
                &:not(:last-child) {
                    margin-bottom: 40px;
                }
            }
        }

        .block__image--row-1 {
            @include aspect(math.div(9, 5));

            grid-column: span 6;

            @include breakpoint(phablet) {
                @include aspect(math.div(7, 5));

                grid-column: span 2;
            }
        }

        .block__image--row-2 {
            @include aspect(math.div(7, 5));

            grid-column: span 3;

            @include breakpoint(phablet) {
                grid-column: span 2;

                figure {
                    &:last-child {
                        display: none;
                    }
                }
            }
        }

        .block__image--row-3 {
            @include aspect(math.div(7, 5));

            grid-column: span 2;

            @include breakpoint(phablet) {
                @include aspect(math.div(4, 3));

                grid-column: span 1;

                figure {
                    &:last-child {
                        display: none;
                    }
                }
            }
        }

    .block__product-grid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 23px;
        margin: 23px 0;

        @include breakpoint(phablet) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    .block__swiper {
        margin: 23px 0;

        @include breakpoint(tablet) {
            margin-right: calc($site-mobile-gutter * -1);
            padding-bottom: 30px;
        }
    }

        .block__nav-wrap {
            display: flex;
            justify-content: flex-end;
            margin-right: -10px;

            @include breakpoint(tablet) {
                display: none;
            }
        }

    .block__callouts {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 23px;
        row-gap: 40px;
        margin: 23px 0;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            max-width: $tablet-max-width;
            margin-left: auto;
            margin-right: auto;
            row-gap: 35px;
        }
    }

    .block__svg-title {
        &--desktop {
            svg {
                max-height: 6rem;
            }
        }

        &--mobile {
            svg {
                max-width: 16rem;
            }
            
            display: none;
        }

        margin-bottom: 1rem;
    
        @include breakpoint(phablet) {
            &--desktop {
                display: none;
            }
    
            &--mobile {
                display: block;
            }

            margin-bottom: .5rem;
        }
    }
}
