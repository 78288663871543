@use 'sass:math';

.product-snapshot {
    text-align: center;
    padding-bottom: 10px;
}

    .product-snapshot__image {
        @include aspect(math.div(3, 2));
        @include margin(bottom, 25px);

        position: relative;

        @include breakpoint(tablet) {
            @include margin(bottom, 15px);
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: opacity 150ms ease-in-out;
        }
    }

    .product-snapshot__image--reveal {
        opacity: 0;

        .product-snapshot:hover & {
            opacity: 1;
        }
    }

    .product-snapshot__image--default {
        .product-snapshot:hover & {
            opacity: 0;
        }
    }

    .product-snapshot__icon {
        max-width: 67px;
        height: auto;
        margin: 0 auto 12px;

        @include breakpoint(tablet) {
            max-width: 54px;
        }

        svg {
            height: auto;
        }
    }

    .product-snapshot__title,
    .product-snapshot__info {
        @include font(17px, 24px);
        @include margin(bottom, 0);

        @include breakpoint(tablet) {
            @include font(13px, 20px);
        }
    }

    .product-snapshot__title {
        @include mediumText;
        @include margin(bottom, 3px);

        @include breakpoint(tablet) {
            @include font(15px, 20px);
        }
    }

    .product-snapshot__info {
        @include font(15px, 24px);

        @include breakpoint(tablet) {
            @include font(13px, 20px);
        }

        &:last-of-type {
            @include margin(bottom, 15px);
        }
    }

    .product-snapshot__price {
        @include margin(bottom, 16px);
    }
