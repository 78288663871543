.block--info-panel {
    padding: 60px 0;
    background-color: var(--site-colour-primary);
    margin-left: calc(((100vw - $site-max-width) / -2) - $site-gutter);
    margin-right: calc(((100vw - $site-max-width) / -2) - $site-gutter);

    @include breakpoint(large) {
        margin-left: calc($site-gutter * -1);
        margin-right: calc($site-gutter * -1);
    }

    @include breakpoint(tablet) {
        padding: 50px 0;
        margin-left: calc($site-mobile-gutter * -1);
        margin-right: calc($site-mobile-gutter * -1);
    }

    .block__grid {
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(tablet) {
            justify-content: normal;
        }
    }

        .block__item {
            position: relative;
            flex: 1;
            display: grid;
            place-items: center;
            text-align: center;

            @include breakpoint(tablet) {
                flex: 1 0 auto;
            }

            &:not(:last-child) {
                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 90px;
                    background-color: var(--site-text-colour-primary);
    
                    @include breakpoint(tablet) {
                        display: none;
                    }
                }
            }
        }

            .block__title {
                @include font(16px, 24px);

                color: var(--site-text-colour-primary);
                max-width: 200px;
            }
}
