.block--signup {
    .block__content {
        max-width: $max-content-width;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 23px;
        padding-right: 10%;

        @include breakpoint(laptop) {
            padding-right: 0;
        }

        @include breakpoint(tablet) {
            max-width: $tablet-max-width;
            flex-direction: column;
            align-items: flex-start;
        }

        @include breakpoint(mobile) {
            align-items: stretch;
        }
    }

        .block__text-wrap {
            max-width: 550px;
            flex-shrink: 0;

            @include breakpoint(tablet) {
                max-width: 100%;
                flex-shrink: 1;
            }
        }

            .block__title {
                margin-bottom: 15px;

                @include breakpoint(tablet) {
                    margin-bottom: 7px;
                }
            }

        .block__form {
            display: flex;

            @include breakpoint(mobile) {
                flex-direction: column;
                gap: 12px;
            }
        }

        .block__thanks {
            flex-basis: 100%;
            text-align: center;

            & > * {
                justify-content: center;
            }
        }
}
