.block--top-tip {
    .block__grid {
        display: grid;
        grid-template-columns: 150px 1fr;
        gap: 2rem;
        align-items: center;
        padding: 0 2rem;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            padding: 0;
        }
    }

    .block__image {
        @include aspect(1);
        @supports (aspect-ratio: 1) {
            aspect-ratio: 1;
        }
        max-width: 150px;
        width: 100%;

        @include breakpoint(tablet) {
            margin-inline: auto;
        }
    }

    .block__content {
        @include breakpoint(tablet) {
            text-align: center;
        }
    }

        .block__title {
            margin-bottom: 10px;

            @include breakpoint(tablet) {
                margin-bottom: 5px;
            }
        }
}
