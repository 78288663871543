.block--svg-with-text {

    .block_wrap {
        display: grid;
        place-items: center;
        text-align: center;
    }   

    .block_svg{
        max-width: 80%;

        svg {
            width: 100%;
        }

        @include breakpoint(tablet) {
            max-width: 90%;
        }
    }

    .block_content {
        width: 70%;
        margin-top: 5px;
        margin-bottom: 0;

        @include breakpoint(tablet) {
            width: 90%;
        }

    }
}

.block[class*="block-background"] {
    padding: 30px 0;
}