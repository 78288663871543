.block--faq,
.block--faq[class*="block-background"] {
    max-width: 1200px;
    margin-inline: auto;
    padding: 80px 20px;

    @include breakpoint(tablet) {
        max-width: 100%;
        padding: 80px 10px;
    }

    .block__content {
        display: flex;
        flex-direction: column;
        gap: 25px;
        max-width: $narrow-max-width;

        @include breakpoint(tablet) {
            max-width: 100%;

        }
    }
}

.faq__text.richtext {
    color: unset;
}
