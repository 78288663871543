@use 'sass:math';

.block--gallery-cta,
.block--callout {
    &.block--right {
        .block__grid {
            grid-template-columns: 1fr 2fr;

            @include breakpoint(tablet) {
                grid-template-columns: 1fr;
            }
        }

        .block__swiper {
            order: 1;

            @include breakpoint(tablet) {
                order: 0;
            }
        }

        .block__content {
            padding-right: 0;
            padding-left: 25%;

            @include breakpoint(tablet) {
                padding: 0;
            }
        }
    }

    .block__grid {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 84px;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            gap: 23px;
            max-width: $tablet-max-width;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .block__grid--image-right {
        grid-template-columns: 1fr 2fr;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
        }

        .block__video,
        .block__swiper {
            order: 1;

            @include breakpoint(tablet) {
                order: initial;
            }
        }

        .block__content {
            padding-left: 25%;
            padding-right: 0;

            @include breakpoint(tablet) {
                padding: 0;
            }
        }
    }

    .block__swiper {
        width: 100%;
        max-width: 100%;
        max-height: 100vh;
        // CSS Grid/Flexbox bug size workaround
        // @see https://github.com/nolimits4web/swiper/issues/3599
        min-height: 0;
        min-width: 0;
    }

        .block__image {
            @include aspect(math.div(9, 5));

            position: relative;

            img {
                width: 100%;
                height: auto;
            }
        }

        .block__content {
            padding-right: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            @include breakpoint(tablet) {
                padding: 0;
            }
        }

            .block__title {
                margin-bottom: 10px;

                @include breakpoint(tablet) {
                    margin-bottom: 5px;
                }
            }

            .block__text {
                ul {
                    display: block;
                    margin-top: 20px;
                    padding-left: 20px;
        
                    li {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        padding-left: 0;
                        margin-bottom: 5px;
        
                        &:last-child {
                            margin-bottom: 0;
                        }
        
                        &::before {
                            content: "";
                            background: 0 50% url("/images/symbols/tick.svg") no-repeat;
                            left: -20px;
                            width: 25px;
                            height: 25px;
                        }
                    }
                }
            }

            .block__cta {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                margin-top: 15px;

                @include breakpoint(tablet) {
                    margin-top: 10px;
                }

                .field {
                    margin: 0;
                }
            }
}
