.global-improved-filters-all-beds {
  width: 140px;
  height: 70px;
}
.st2{fill:#2e2d2c}

.global-improved-filters-french-bed {
  width: 140px;
  height: 70px;
}
.st2{fill:#2e2d2c}

.global-improved-filters-storage-bed {
  width: 140px;
  height: 70px;
}
.st2{fill:#2e2d2c}

.global-improved-filters-upholstered-bed {
  width: 140px;
  height: 70px;
}
.st2{fill:#2e2d2c}

.global-improved-filters-wooden-bed {
  width: 140px;
  height: 70px;
}
.st2{fill:#2e2d2c}

.global-icon-360 {
  width: 37px;
  height: 30px;
}

.global-icon-add-alt {
  width: 15px;
  height: 15px;
}

.global-icon-add-wishlist {
  width: 25px;
  height: 22px;
}

.global-icon-add {
  width: 19px;
  height: 19px;
}

.global-icon-added-wishlist {
  width: 25px;
  height: 22px;
}

.global-icon-arrow-down-small {
  width: 12px;
  height: 8px;
}

.global-icon-arrow-down {
  width: 14px;
  height: 9px;
}

.global-icon-arrow-left {
  width: 10px;
  height: 15px;
}

.global-icon-arrow-right-double {
  width: 20px;
  height: 20px;
}

.global-icon-arrow-right {
  width: 10px;
  height: 15px;
}

.global-icon-back {
  width: 7.989px;
  height: 14.8px;
}

.global-icon-basket-qty-down {
  width: 14px;
  height: 2px;
}

.global-icon-basket-qty-up {
  width: 14px;
  height: 14px;
}

.global-icon-basket {
  width: 21px;
  height: 21px;
}

.global-icon-bestseller {
  width: 26px;
  height: 28px;
}

.global-icon-blog {
  width: 87px;
  height: 56px;
}

.global-icon-brochure {
  width: 19px;
  height: 15px;
}

.global-icon-chat {
  width: 22px;
  height: 20px;
}

.global-icon-circle-no {
  width: 35px;
  height: 35px;
}

.global-icon-circle-yes {
  width: 35px;
  height: 35px;
}

.global-icon-close-large {
  width: 19px;
  height: 19px;
}

.global-icon-close-small {
  width: 20px;
  height: 20px;
}

.global-icon-cross-round {
  width: 20px;
  height: 20px;
}

.global-icon-cross {
  width: 19px;
  height: 19px;
}

.global-icon-deliveries {
  width: 87px;
  height: 56px;
}

.global-icon-delivery {
  width: 18px;
  height: 18px;
}

.global-icon-dixa {
  width: 30px;
  height: 26px;
}

.global-icon-double {
  width: 20px;
  height: 20px;
}

.global-icon-dry-clean {
  width: 33px;
  height: 37px;
}

.global-icon-elevator-down {
  width: 34px;
  height: 34px;
}

.global-icon-elevator-up {
  width: 34px;
  height: 34px;
}

.global-icon-fabric-add {
  width: 14px;
  height: 14px;
}

.global-icon-fabric-remove {
  width: 14px;
  height: 14px;
}

.global-icon-filter-all_types {
  width: 140px;
  height: 70px;
}

.global-icon-filter-armchair-chaise {
  width: 140px;
  height: 70px;
}

.global-icon-filter-armchair {
  width: 140px;
  height: 70px;
}

.global-icon-filter-button-back {
  width: 140px;
  height: 70px;
}

.global-icon-filter-chaise-sofa {
  width: 140px;
  height: 70px;
}

.global-icon-filter-corner-sofa {
  width: 140px;
  height: 70px;
}

.global-icon-filter-footstool {
  width: 140px;
  height: 70px;
}

.global-icon-filter-four-plus-seater-sofa {
  width: 140px;
  height: 70px;
}

.global-icon-filter-large-cushions {
  width: 140px;
  height: 70px;
}

.global-icon-filter-love-seat {
  width: 140px;
  height: 70px;
}

.global-icon-filter-modular-sofa {
  width: 140px;
  height: 70px;
}

.global-icon-filter-no-plumping {
  width: 140px;
  height: 70px;
}

.global-icon-filter-small-cushions {
  width: 140px;
  height: 70px;
}

.global-icon-filter-sofa {
  width: 140px;
  height: 70px;
}
.st1{fill:#0d0d0d}

.global-icon-filter-sofabed {
  width: 140px;
  height: 70px;
}

.global-icon-filter-sprung-back {
  width: 140px;
  height: 70px;
}

.global-icon-filter-three-seater-sofa {
  width: 140px;
  height: 70px;
}

.global-icon-filter-two-seater-sofa {
  width: 140px;
  height: 70px;
}

.global-icon-gift {
  width: 19px;
  height: 19px;
}

.global-icon-hamburger {
  width: 25px;
  height: 15px;
}

.global-icon-has-filters {
  width: 18px;
  height: 20px;
}

.global-icon-help {
  width: 87px;
  height: 56px;
}

.global-icon-info {
  width: 16px;
  height: 16px;
}

.global-icon-location {
  width: 35px;
  height: 45px;
}

.global-icon-makers {
  width: 87px;
  height: 56px;
}

.global-icon-menu-close {
  width: 85px;
  height: 85px;
}

.global-icon-minus {
  width: 10px;
  height: 2px;
}

.global-icon-pause-video {
  width: 60px;
  height: 70px;
}

.global-icon-phone-alt {
  width: 21px;
  height: 20px;
}

.global-icon-phone {
  width: 57px;
  height: 50px;
}

.global-icon-pinterest {
  width: 60px;
  height: 50px;
}

.global-icon-play-video {
  width: 61px;
  height: 74px;
}

.global-icon-plus-circle {
  width: 18px;
  height: 19px;
}

.global-icon-plus {
  width: 10px;
  height: 10px;
}

.global-icon-position {
  width: 18px;
  height: 18px;
}

.global-icon-remove-alt {
  width: 15px;
  height: 1px;
}

.global-icon-remove {
  width: 14px;
  height: 14px;
}

.global-icon-returns-bird {
  width: 204px;
  height: 216px;
}

.global-icon-returns {
  width: 87px;
  height: 56px;
}

.global-icon-reviews {
  width: 191px;
  height: 42px;
}

.global-icon-scissors {
  width: 88px;
  height: 48px;
}

.global-icon-search {
  width: 20px;
  height: 20px;
}

.global-icon-secure-site {
  width: 40px;
  height: 56px;
}

.global-icon-shack {
  width: 18px;
  height: 19px;
}

.global-icon-single {
  width: 20px;
  height: 20px;
}

.global-icon-small-trolley {
  width: 29px;
  height: 24px;
}

.global-icon-social-print {
  width: 24px;
  height: 24px;
}

.global-icon-social-whatsapp {
  width: 24px;
  height: 24px;
}

.global-icon-stars {
  width: 20px;
  height: 18px;
}

.global-icon-submit {
  width: 31px;
  height: 20px;
}

.global-icon-swatch {
  width: 24px;
  height: 24px;
}

.global-icon-swatches-alt {
  width: 31px;
  height: 25px;
}

.global-icon-swatches {
  width: 24px;
  height: 24px;
}

.global-icon-tick-round {
  width: 20px;
  height: 20px;
}

.global-icon-tick {
  width: 13px;
  height: 10px;
}

.global-icon-tooltip {
  width: 7px;
  height: 9px;
}

.global-icon-van {
  width: 19px;
  height: 19px;
}

.global-icon-wishlist {
  width: 30px;
  height: 30px;
}

.global-icon-zoom-in {
  width: 20px;
  height: 20px;
}

.global-loaf-logo {
  width: 84px;
  height: 80px;
}

.global-mug {
  width: 873.31px;
  height: 959.34px;
}
.c,.e{fill:none}
.e{stroke-linecap:round;stroke-linejoin:round;stroke-width:0;stroke:#0d0d0d}
.f{fill:#fff}
.g{fill:#0d0d0d}

.global-qty-minus {
  width: 15px;
  height: 1px;
}

.global-qty-plus {
  width: 15px;
  height: 15px;
}

.global-star {
  width: 432.4px;
  height: 413.49px;
}

.global-swatch-mask {
  width: 38px;
  height: 38px;
}

.global-tick {
  width: 13px;
  height: 10px;
}
